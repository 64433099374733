import React from "react";
import { AuthProvider, oktaAuth } from "./AuthContext";
import { LoadingContextProvider } from "./LoadingContext";
import HttpInterceptor from "../api/HttpInterceptor";
import { ThemeProvider } from "styled-components";
import { Theme } from "best-common-react";
import { AppTheme } from "../AppTheme";
import { AlertsProvider } from "./AlertsContext";
import { Security } from "@okta/okta-react";
import { CachedDataProvider } from "./CachedDataContext";

type WrapperProviderProps = {
    children: React.ReactNode;
};

const WrapperProvider: React.FC<WrapperProviderProps> = ({ children }) => {
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        window.location.replace(originalUri);
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <AuthProvider>
                <LoadingContextProvider>
                    <HttpInterceptor>
                        <AlertsProvider>
                            <CachedDataProvider>
                                <ThemeProvider theme={{ ...Theme, ...AppTheme }}>{children}</ThemeProvider>
                            </CachedDataProvider>
                        </AlertsProvider>
                    </HttpInterceptor>
                </LoadingContextProvider>
            </AuthProvider>
        </Security>
    );
};

export default WrapperProvider;
