import styled from "styled-components";

const DisabledButton = styled.button.attrs(() => ({
    name: "disabled-button"
}))`
    border: 1px solid;
    cursor: ${(props) => (props.disabled ? "disabled" : "pointer")};
    ${(props) => (props.disabled ? "background-color: #f2f3f4 !important;" : "")}
    ${(props) => (props.disabled ? `color: ${props.theme.grey} !important;` : "")}
  ${(props) => (props.disabled ? "border-color: #c9c9c9 !important;" : "")}
`;

const PrimaryButton = styled(DisabledButton).attrs(() => ({
    className: "btn btn-primary",
    name: "primary-button"
}))`
    &&& {
        color: #ffffff;
        background-color: #4c8cee;
        border-color: #4c8cee;
    }
`;

type PrimaryButtonStyledProps = {
    height: number;
    width: number;
};

export const PrimaryButtonStyled = styled(PrimaryButton).attrs(() => ({
    className: "justify-content-center d-flex",
    type: "submit",
    name: "primary-button"
}))<PrimaryButtonStyledProps>`
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
    border-radius: 6px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #4c8cee;
    border-color: #4c8cee;
    color: #4c8cee;
    align-items: center;
    /* firefox only */
    @supports (-moz-appearance: none) {
        line-height: ${(props) => `${props.height - 2}px`};
    }
`;
