import React from "react";
import { AlertHolder } from "best-common-react";
import styled from "styled-components";
import { useAlertContext } from "../../../contexts/AlertsContext";

const SureAlertWrapper = styled.div`
    > div {
        > div {
            display: table;
            left: 0px;
            right: 0px;
            margin: 0 auto;
            width: fit-content;
            white-space: nowrap;
        }
    }
`;

const SureAlerts = () => {
    const { alerts, removeAlert } = useAlertContext();

    return (
        <SureAlertWrapper id="sure-alerts">
            <AlertHolder
                fullWidth
                alerts={alerts}
                onRemove={(id: number) => {
                    if (id) {
                        removeAlert(id);
                    }
                }}
            />
        </SureAlertWrapper>
    );
};

export default SureAlerts;
