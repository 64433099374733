import React from "react";
import LoginComponent from "../LoginComponent";
import styled from "styled-components";
import logo from "../../../assets/sure-logo@2x.png";

const TopGradientBg = styled.div.attrs({})`
    position: absolute;
    top: 0;
    height: 370px;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #1b3b68;
`;

const LowerHalfBg = styled.div.attrs({})`
    position: absolute;
    z-index: -1;
    top: 370px;
    left: 0;
    right: 0;
    height: calc(100vh - 370px);
    background-color: #041e42;
`;

const OuterContainer = styled.div.attrs({
    className: "d-flex flex-col"
})``;

const LoginFormHolder = styled.div.attrs({
    className: "d-flex"
})`
    flex-flow: column;
    position: relative;
    top: 309px;
    float: right;
    padding-right: 90px;
`;

const Logo = styled.img.attrs(() => ({
    className: "img-fluid mx-auto d-block mt-4 "
}))`
    max-height: 151px;
    width: 400px;
`;

const LogoHolder = styled.div.attrs(() => ({
    className: "d-flex"
}))``;

const LoginComponentHolder = styled.div.attrs({})`
    text-align: right;
`;

const LoginContainer: React.FC = () => {
    return (
        <OuterContainer>
            <div className="col">
                <TopGradientBg />
                <LowerHalfBg />
                <LoginFormHolder>
                    <LogoHolder>
                        <Logo src={logo} />
                    </LogoHolder>
                    <LoginComponentHolder>
                        <LoginComponent />
                    </LoginComponentHolder>
                </LoginFormHolder>
            </div>
        </OuterContainer>
    );
};

export default LoginContainer;
