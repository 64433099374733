import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import styled from "styled-components";
import { Icon } from "best-common-react";
import { PrimaryButtonStyled } from "../elements/PrimaryButtonStyled";
import { useOktaAuth } from "@okta/okta-react";

const Key = styled(Icon).attrs({
    iconName: "fa-key"
})`
    &&& {
        color: ${(props) => props.theme["MLB-navy"]};
    }

    padding-right: 10px;
`;

const LoginButton = styled(PrimaryButtonStyled).attrs({})`
    &&& {
        background-color: ${(props) => props.theme["white"]};
        color: ${(props) => props.theme["MLB-navy"]};
    }

    float: right;
    margin-top: 22px;
`;

const SubTitle = styled.div.attrs({})`
    font-style: helvetica;
    font-size: 24px;
    font-weight: 300;
    color: ${(props) => props.theme["blue-grey"]};
`;

const LoginComponent: React.FC<RouteComponentProps> = ({ history }) => {
    const { loggedIn } = useAuth();
    const { oktaAuth } = useOktaAuth();

    const loginFn = () => {
        oktaAuth.signInWithRedirect({ originalUri: RouteConstants.BASE });
    };

    return (
        <div>
            {!loggedIn ? (
                <div>
                    <SubTitle>Umpire Review and Evaluation</SubTitle>
                    <div>
                        <LoginButton onClick={loginFn} height={38} width={198}>
                            <div className="d-flex align-items-center">
                                <Key />
                                <div>Sign in with MLB Okta</div>
                            </div>
                        </LoginButton>
                    </div>
                </div>
            ) : (
                <Redirect to={RouteConstants.BASE} />
            )}
        </div>
    );
};

export default withRouter(LoginComponent);
