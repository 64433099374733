import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SureProtectedContents from "./modules/common/SureProtectedContents";
import "./App.css";
import SureAlerts from "./modules/common/alerts/SureAlerts";

const App: React.FC = () => {
    return (
        <Suspense fallback={<h1>loading...</h1>}>
            <SureAlerts />
            <BrowserRouter>
                <Switch>
                    <Route component={SureProtectedContents} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};

export default App;
