import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { getUserInfo } from "../api/UsersApi";
import config from "../config";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

type UserInfo = {
    accessToken: { accessToken: string };
    userName: string;
    userFullName: string;
    email: string;
    orgId: number;
    orgName: string;
    orgShortName: string;
    roles: Array<string>;
    userId: number;
};

// Type for passing around role information in one object
type UserRoleInfo = {
    isAdmin: boolean;
    isDisputeResolver: boolean;
    isSupervisor: boolean;
    isUmpire: boolean;
};

type UserContext = {
    userInfo: UserInfo;
    userRoleInfo: UserRoleInfo;
    loggedIn: boolean;
    isAdmin: () => boolean;
    isDisputeResolver: () => boolean;
    isSupervisor: () => boolean;
    isImpersonatingSupervisor: () => boolean;
    isUmpire: () => boolean;
    isImpersonatingUmpire: () => boolean;
    login: (data: UserInfo) => void;
    logout: () => void;
    permissionError: boolean;
    loginFunction: SetStateAction<void>;
    setPermissionError: Dispatch<SetStateAction<boolean>>;
    setLoginFunction: Dispatch<SetStateAction<void>>;
    userId: number;
    oktaLoginFunc: { loginFunction: Function };
    setOktaLoginFunc: Dispatch<SetStateAction<Function>>;
};

const AuthContext = createContext<UserContext>({
    userInfo: null,
    userRoleInfo: null,
    loggedIn: false,
    login: null,
    logout: null,
    isAdmin: null,
    isDisputeResolver: null,
    isSupervisor: null,
    isUmpire: null,
    isImpersonatingSupervisor: null,
    isImpersonatingUmpire: null,
    setIsImpersonatingSupervisor: null,
    setIsImpersonatingUmpire: null,
    permissionError: false,
    loginFunction: null,
    setPermissionError: null,
    setLoginFunction: null,
    triggerRefresh: null,
    setTriggerRefresh: null
});

const initialState = {
    userInfo: {
        accessToken: { accessToken: "" },
        userName: "",
        userFullName: "",
        email: "",
        orgId: 0,
        orgName: "",
        orgShortName: "",
        roles: ["NONE"],
        userId: 0
    },
    userRoleInfo: {
        isAdmin: false,
        isDisputeResolver: false,
        isSupervisor: false,
        isUmpire: false
    },
    loggedIn: false
};

const oktaAuth = new OktaAuth(config.oidc);

const AuthProvider: React.FC = ({ children }) => {
    const [userState, setUserState] = useState({
        userInfo: initialState.userInfo,
        userRoleInfo: initialState.userRoleInfo
    });
    const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
    const [permissionError, setPermissionError] = useState<boolean>(false);
    const [loginFunction, setLoginFunction] = useState(null);
    const [isImpersonatingSupervisor, setIsImpersonatingSupervisor] = useState(false);
    const [isImpersonatingUmpire, setIsImpersonatingUmpire] = useState(false);
    const [oktaLoginFunc, setOktaLoginFunc] = useState(null);
    const { authState } = useOktaAuth();

    const userId = () => {
        return userState.userInfo.userId;
    };

    const isAdmin = () => {
        return userState.userInfo.roles.includes("SURE_ADMIN") && !(isImpersonatingSupervisor || isImpersonatingUmpire);
    };

    const isDisputeResolver = () => {
        return userState.userInfo.roles.includes("SURE_DISPUTE_RESOLVER") && !isImpersonatingSupervisor;
    };

    const isSupervisor = () => {
        return userState.userInfo.roles.includes("SURE_SUPERVISOR") || isImpersonatingSupervisor;
    };

    const isUmpire = () => {
        return userState.userInfo.roles.includes("SURE_UMPIRE") || isImpersonatingUmpire;
    };

    const getUserRoles = (userData) => {
        return {
            isAdmin: userData.roles.includes("SURE_ADMIN"),
            isDisputeResolver: userData.roles.includes("SURE_DISPUTE_RESOLVER"),
            isSupervisor: userData.roles.includes("SURE_SUPERVISOR"),
            isUmpire: userData.roles.includes("SURE_UMPIRE")
        };
    };

    const onLogin = async (userData: UserInfo) => {
        const userInfoData = await getUserInfo();
        if (userInfoData) {
            userData = { ...userData, ...userInfoData };
            setUserState({
                userInfo: userData,
                userRoleInfo: getUserRoles(userData)
            });
            setLoggedIn(true);
        }
    };

    const logout = () => {
        localStorage.clear();
        setUserState({ userInfo: initialState.userInfo, userRoleInfo: initialState.userRoleInfo });
        setLoggedIn(false);
    };

    const getUserRoleForDisplay = () => {
        if (isAdmin()) {
            return "Admin";
        } else if (isDisputeResolver()) {
            return "Resolver";
        } else if (isSupervisor()) {
            return "Supervisor";
        } else {
            return "Umpire";
        }
    };

    const getUserRoleForDisplayShort = () => {
        return getUserRoleForDisplay().substr(0, 3);
    };

    useEffect(() => {
        if (authState?.isAuthenticated) {
            // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
            onLogin({ accessToken: authState.accessToken });
        }
    }, [authState]);

    return (
        <AuthContext.Provider
            value={{
                userInfo: userState.userInfo,
                userRoleInfo: userState.userRoleInfo,
                loggedIn,
                isAdmin,
                isDisputeResolver,
                isSupervisor,
                isUmpire,
                onLogin,
                logout,
                permissionError,
                setPermissionError,
                loginFunction,
                setLoginFunction,
                isImpersonatingSupervisor,
                isImpersonatingUmpire,
                setIsImpersonatingSupervisor,
                setIsImpersonatingUmpire,
                getUserRoleForDisplay,
                getUserRoleForDisplayShort,
                userId,
                oktaLoginFunc,
                setOktaLoginFunc
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): UserContext => {
    const authContext = useContext<UserContext>(AuthContext);
    if (authContext === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return authContext;
};

export { AuthContext, AuthProvider, oktaAuth, useAuth, UserRoleInfo, UserInfo };
