export const RouteConstants = {
    LOGIN: "/login",
    LOGIN_CALLBACK: "/login/callback",
    BASE: "/",
    HOME: "/home",
    GAMES: "/games",
    DISPUTES: "/disputes",
    SETTINGS: "/settings",
    USERS: "/users",
    REPORTS: "/reports",
    OPEN_DISAGREEMENTS: "/open-disagreements",
    GAME_OVERRIDES: "/game-overrides"
};
