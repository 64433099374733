// Overrides for the default BCR theme

export const AppTheme: any = {
    "MLB-navy": "#041e42",
    "blue-grey": "#92a5ba",
    fog: "#dee5ed",
    "SURE-blue": "#1b3b68",
    "MLB-blue": "#002c73",
    blue: "#4c8cee",
    cloud: "#f5f7fa",
    slate: "#77879a",
    snow: "#fafbfc"
};
