import React, { createContext, useContext, useReducer, useRef } from "react";

export type CachedData = {};

type CachedDataContext = {
    cachedDataState: CachedData;
    dispatchCachedData: React.Dispatch<any>;
};

const initialState = {
    gameSearchForm: null,
};

const CachedDataContext = createContext<CachedDataContext>({
    cachedDataState: initialState,
    dispatchCachedData: null,
});

export const CachedDataAction = {
    CACHE_GAME_SEARCH_FORM: "CACHE_GAME_SEARCH_FORM"
};

const reducer = (state: CachedData, action: any) => {
    switch (action.type) {
        case CachedDataAction.CACHE_GAME_SEARCH_FORM:
            const form = action.gameState;
            return {
                ...state,
                gameSearchForm: form,
            }
        default:
            return {
                ...state
            }
    }
}

export const CachedDataProvider: React.FC = ({ children }) => {
    const [cachedDataState, dispatchCachedData] = useReducer(reducer, initialState);
    return (
        <CachedDataContext.Provider
            value={{
                cachedDataState,
                dispatchCachedData
            }}
        >
            {children}
        </CachedDataContext.Provider>
    );
}

export const useCachedData = (): CachedDataContext => {
    const context = useContext<CachedDataContext>(CachedDataContext);

    if (context == undefined) {
        throw new Error(`useCachedData must be used within a CachedDataContext`);
    }
    return context;
}
